import "@heeros/ag-grid/dist/styles/style.css";
import 'ag-grid-enterprise';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import TaimerComponent from '../TaimerComponent';
import VersionContentManager from '../general/VersionContentManager';
import WithTabs, { Tab } from '../navigation/WithTabs';
import DataHandler from './../general/DataHandler';
import getCostsColumns from './CostsColumns';
import getInvoicingColumns from './InvoicingColumns';
import getScheduledInvoicingColumns from './ScheduledInvoicingColumns';
import LinkCellRenderer from './LinkCellRenderer';
import getProjectDataColumns from './ProjectDataColumns';
import getForecastColumns from './ForecastColumns';
import getActivitiesColumns from './ActivitiesColumns';
import getSalesColumns from './SalesColumns';
import StatusCellRenderer from './StatusCellRenderer';
import DataList from './../general/DataList';
import StyledTooltip from './../general/StyledTooltip';
import ReportService from "./ReportService";
import handleDimensionColumns from "./DimensionColumns";
import { startOfMonth, endOfMonth } from "date-fns";
import { timestamp } from "../general/DateTimeUtils";
import { 
    InfoOutlined, 
    AttachMoney 
} from '@mui/icons-material';
import getColumnType from './ColumnTypes';
import cardStyles from '../general/styles/CardStyles.module.scss';
import Utils from '../general/Utils';

import {
    ColDef,
    DataTypeDefinition,
} from 'ag-grid-community';


/* context */
import { SettingsContext } from './../SettingsContext';

/* css */
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS  
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { DateRangePicker } from '../general/react-date-range/src';
import { CustomField } from "./CustomFields";
import getDataTypeDefinitions from './DataTypeDefinitions';
import './NewReportsView.css';
import getWorkinghourColumns from './WorkinghourColumns';
import getAccountDataColumns from './AccountDataColumns';
import { ReactComponent as CalendarIcon } from './img/calendar.svg';
import AgGridWrapper from './components/AgGridWrapper';
import { MenuItem, Tooltip } from '@mui/material';
import React from 'react';
import { FilterState, defaultFilterState } from './FiltersState';
import SaveTemplateDialog, { Props as SaveTemplateDialogProps, Template as SaveTemplateDialogTemplate } from './SaveTemplateDialog';
import { 
    Close, 
    CloudDownload, 
    Download, 
    FilterAlt, 
    GridView, 
    Add,
    UnfoldMore,
    UnfoldLess,
} from '@mui/icons-material';
import ReportsGallery from './components/ReportsGallery';
import { FilterDataJson, TemplateData, TemplateListItem, getTemplateData, getTemplatesForUser, reportNameToReportType, reportTypeToTab } from './TemplateUtils';
import _ from 'lodash';
import ContextMenu from '../general/ContextMenu';
import SummarizeWithAIButton from './SummarizeWithAIButton';

interface Props extends WithSnackbarProps {
    selectedTab?: string;
    selectedSubTab?: string;
    updateView: (update: object, options: object | null, stateRefresh: boolean,
        rememberPage: boolean, noWhoami: boolean) => void;
    updateViewOriginPoint?: string;
}

interface Report {
    id: number
}

interface Currency {
    id: string;
    name: string;
    label: string;
}

interface State {
    isLoading: boolean;
    isApplyingFilterState: boolean;
    galleryOpen: boolean;
    rows: object[];
    columns: ColDef[];
    datas: {
        workinghours?: object[] | undefined,
        costs?: object[] | undefined,
        projects?: object[] | undefined,
        invoicing?: object[] | undefined,
        forecast?: object[] | undefined,
        sales?: object[] | undefined,
    };
    columnDefs: {
        workinghours?: ColDef[] | undefined,
        costs?: ColDef[] | undefined,
        projects?: ColDef[] | undefined,
        invoicing?: ColDef[] | undefined,
        forecast?: object[] | undefined,
        sales?: object[] | undefined,
    },
    tabs: Tab[];
    selectedTab: string;
    custom_reports: Report[];
    reportDateRange: {
        key: "selection",
        startDate: Date | null;
        endDate: Date | null;
    };
    relativeReportDateRangeSelection: string | null;
    relativeReportDateRangeAbsolute: {
        startDate: Date;
        endDate: Date;
    } | null;
    materialDateRange: {
        key: "selection",
        startDate: Date | null;
        endDate: Date | null;
    };
    relativeMaterialDateRangeSelection: string | null;
    relativeMaterialDateRangeAbsolute: {
        startDate: Date;
        endDate: Date;
    } | null;
    materialDateRangeChanged: boolean;
    reportDateRangeChanged: boolean;
    customFields: CustomFieldsData;
    switchingTab: boolean;
    currencies: Currency[];
    selectedCurrency: string;
    filterState: FilterState;
    dataTypeDefinitions: Record<string, DataTypeDefinition>;
    saveTemplateDialogOpen: boolean;
    createReportDialogOpen: boolean;
    saveTemplateData?: FilterDataJson;
    templates: TemplateListItem[];
    openTemplates: number[];
    template?: TemplateData | null;
    autoCompleteData: {
        groups: any[],
        companies: any[],
        users: any[]
    },
    saveTemplateDialogData?: SaveTemplateDialogData | null;
    lastOpenedReport: string;
    summary?: string;
    summaryLoading?: boolean;
    activeFilterData?: any;
}

interface SaveTemplateDialogData {
    template: TemplateData;
    noFilterStateSave?: boolean;
}

interface CustomFieldsData {
    customers: CustomField[];
    projects: CustomField[];
}

type ChangeTabParams = {
    tab?: string;
    templateId?: number | null;
    refresh?: boolean;
    initial?: boolean;
    changeOriginPoint?: string;
}

function parseTemplateId(subtab: string | undefined | null): number|null {
    if (subtab && subtab.startsWith("template_")) {
        subtab = subtab.substring(9);

        const id = Number(subtab);

        if (!isNaN(id) && id != 0) {
            return id;
        }
    }

    return null;
}

function readLocalStorage(key: string): number[] {
    const val = localStorage[key] ? JSON.parse(localStorage[key]) : null;

    if (!Array.isArray(val)) {
        return [];
    }

    return val.map(x => Number(x)).filter(x => x > 0 && !isNaN(x));
}

function writeLocalStorage(key: string, value: number[]) {
    localStorage[key] = JSON.stringify(value);
}

class NewReportsView extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    grid = React.createRef<AgGridWrapper>();

    translations = {};
    private reportService: ReportService;
    private previousTemplateId: number | undefined = undefined;
    private lastDataFetchTimestamp = 0;
    private activityTypeIcons = {};

    // For example: ag_grid_state_for_invoicing
    readonly STATE_CONFIG_PREFIX: string = "ag_grid_state_for_";

    readonly OPEN_TEMPLATES_KEY: string;

    constructor(props, context) {
        super(props, context, 'new_reports/NewReportsView');

        this.reportService = new ReportService();

        const selectedCurrency = context.taimerAccount.currency;
        const dataTypeDefinitions = getDataTypeDefinitions(this.context, { selectedCurrency });

        this.OPEN_TEMPLATES_KEY = this.getLocalStorageKey("open_templates");

        const openTemplates = readLocalStorage(this.OPEN_TEMPLATES_KEY);

        this.activityTypeIcons = Utils.importAllIcons(require.context('../settings/img/Activity_icons/', false, /\.(png|jpe?g|svg)$/));

        this.state = {
            tabs: this.updateTabs([], openTemplates),
            selectedTab: this.props.selectedTab || 'invoicing',
            rows: [],
            columns: [],
            templates: [],
            openTemplates,
            custom_reports: [],
            dataTypeDefinitions,
            datas: {
                workinghours: undefined,
                costs: undefined,
                projects: undefined,
                invoicing: undefined,
                forecast: undefined,
                sales: undefined,
            },
            columnDefs: {
                workinghours: undefined,
                costs: undefined,
                projects: undefined,
                invoicing: undefined,
                forecast: undefined,
                sales: undefined,
            },
            reportDateRange: {
                key: "selection",
                startDate: startOfMonth(new Date()),
                endDate: endOfMonth(new Date())
            },
            relativeReportDateRangeSelection: null,
            materialDateRange: {
                key: "selection",
                startDate: null,
                endDate: null,
            },
            relativeMaterialDateRangeSelection: null,
            materialDateRangeChanged: false,
            reportDateRangeChanged: false,
            customFields: {
                customers: [],
                projects: [],
            },
            currencies: [
                { id: context.taimerAccount.currency, name: context.taimerAccount.currency, label: context.taimerAccount.currency },
            ],
            selectedCurrency,
            switchingTab: false,
            isLoading: true,
            isApplyingFilterState: false,
            galleryOpen: false,
            filterState: {
                columns: [],
                grouping: [],
                filterModel: {},
                pivotMode: false,
            },
            saveTemplateDialogOpen: false,
            createReportDialogOpen: false,
            autoCompleteData: {
                groups: [],
                companies: [],
                users: []
            },
            saveTemplateDialogData: null,
            lastOpenedReport: "",
            activeFilterData: {},
            relativeReportDateRangeAbsolute: null,
            relativeMaterialDateRangeAbsolute: null
        };
    }

    updateTabs = (templates: TemplateListItem[]|null, openTemplates: number[]): Tab[] => {
        const baseTabs = VersionContentManager.getViewTabs(this.namespace).map(tab => {
            tab.label = this.tr(tab.label);
            tab.tooltip = tab.tooltip ? this.tr(tab.tooltip) : tab.toolip;

            return tab;
        }) as Tab[];

        const templatesById = _.keyBy(templates, x => x.id);

        const pinnedTemplatesByTab = _.chain(templates)
            .filter(x => x.is_favorite)
            .sortBy(x => [!x.is_default, !x.is_favorite, x.name?.toLowerCase(), x.order_nr, x.id])
            .groupBy(x => x.report_type)
            .value();

        const temporaryTemplatesByTab = _.chain(_.map(openTemplates, x => templatesById[x]))
            .filter(x => !!x && !x.is_favorite)
            .groupBy(x => x.report_type)
            .value();

        return baseTabs.map(x => {

            const pinnedTabs = (pinnedTemplatesByTab[reportNameToReportType(x.id)] ?? []).map<Tab>(x => ({
                id: `template_${x.id}`,
                label: x.is_default ? this.tr(x.name) : x.name,
            }));
            const temporaryTabs = (temporaryTemplatesByTab[reportNameToReportType(x.id)] ?? []).map<Tab>(x => ({
                id: `template_${x.id}`,
                label: x.is_default ? this.tr(x.name) : x.name,
                closeable: true,
            }));

            const divider: Tab[] = pinnedTabs.length && temporaryTabs.length ? [{
                type: 'divider',
                id: 'divider',
                label: '',
            }] : [];

            return ({
                ...x,
                showWithEmptyItems: true,
                items: templates !== null ? [
                    ...pinnedTabs,
                    ...divider,
                    ...temporaryTabs,
                ] : x.items,
            });
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        const { selectedTab, selectedSubTab } = this.props;

        if(prevProps.selectedTab !== selectedTab || prevProps.selectedSubTab !== selectedSubTab) {
            this.switchTabAfterPropChange();
        }

        this.previousTemplateId = prevState.template?.id;
    }

    switchTabAfterPropChange = () => {
        const { selectedTab, selectedSubTab } = this.props;

        const tab        = this.state.tabs.find(x => x.id === selectedTab);
        const templateId = parseTemplateId(selectedSubTab);

        if(!tab && !templateId) {
            return;
        }

        this.setState({ isLoading: true }, () => {
            this.getData({
                tab: tab?.id,
                templateId,
            });
        });
    }

    setDirty = (isDirty) => {
        this.context.functions.setDirty(isDirty, false, {
            title: this.tr('Report is not saved!'),
            text: this.tr('Are you sure you want to leave? All unsaved changes will be lost.'),
        });
    }

    getConfiguration = (mainReport, report) => {
        const reports = {
            invoicing: {
                invoicing_vs_subcontracting: {
                    columns: [
                        { field: 'company' },
                        { field: 'date', cellDataType: 'date' },
                        {
                            field: 'status', cellRenderer: StatusCellRenderer, cellRendererParams: {
                                displayDatas: [
                                    { id: 1, name: "Eka", color: '#2d9ff7' },
                                    { id: 2, name: "Toka", color: '#ffb822' }
                                ]
                            }
                        },
                        { field: 'link', /*cellDataType: 'link',*/ cellRenderer: LinkCellRenderer },
                        { field: 'project', /*cellDataType: 'link',*/ cellRenderer: LinkCellRenderer },
                        { field: 'customer_name' },
                        { field: 'enterprise_group' },
                        { field: 'units_name' },
                        { field: 'units_type' },
                        { field: 'project_name' },
                        { field: 'branch_of_business' },
                        { field: 'vatid' },
                        { field: 'hours_billable', cellDataType: 'currency' },
                        { field: 'hours_billable_price', cellDataType: 'currency' },
                        { field: 'hours_billed', cellDataType: 'currency' },
                        { field: 'hours_billed_list_price', cellDataType: 'currency' },
                        { field: 'hours_sum', cellDataType: 'currency' },
                        { field: 'sum_pete_not_billed', cellDataType: 'currency' },
                        { field: 'sum_pete_billed', cellDataType: 'currency' },
                        { field: 'sum_re_not_billed', cellDataType: 'currency' },
                        { field: 'sum_re', cellDataType: 'currency' },
                        { field: 'billed_sum', cellDataType: 'currency' },
                    ]
                },
                invoices_summary: {
                    columns: [
                        { field: 'company' },
                        { field: 'bill_id' },
                        { field: 'creationdate' },
                        { field: 'customer_name' },
                        { field: 'enterprise_group' },
                        { field: 'project_name' },
                        { field: 'branch_of_business' },
                        { field: 'customerreference' },
                        { field: 'reporting_group' },
                        { field: 'customer_references' },
                        { field: 'period' },
                        { field: 'vatid' },
                        { field: 'billed_own_cost', type: 'currency' },
                        { field: 'billed_subcontracting', type: 'numericColumn' },
                        { field: 'net_total', type: 'numericColumn' },
                        { field: 'vat', type: 'numericColumn' },
                        { field: 'vat_total', type: 'numericColumn' },
                        { field: 'gross_total', type: 'numericColumn' },
                        { field: 'paid_total', type: 'numericColumn' },
                        { field: 'paid_date', type: 'numericColumn' },
                        { field: 'billing_zone' },
                    ]
                }
            }
        };
        const conf = reports[mainReport][report] ?? reports['invoicing']['invoicing_vs_subcontracting'];
        conf.columns.map(el => {
            if (el.rowGroup !== true) {
                el.rowGroup = false;
            }
            return el;
        });
        return conf;
    }

    getCustomConfiguration = (id) => {
        const report = this.state.custom_reports.find(el => el.id == id);
        return report;
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.init();
    };

    init = async () => {
        const { selectedTab, selectedSubTab } = this.props;
        const { openTemplates } = this.state;
        const { userObject: { usersId } } = this.context;

        this.getAutoCompleteData(selectedTab);

        const templates = await getTemplatesForUser(usersId, this.tr);
        const tabs = this.updateTabs(templates, openTemplates);

        const currentTab: Tab|undefined = tabs.find(x => x.id === selectedTab) ?? tabs?.[0];

        const propsSubTabId = parseTemplateId(selectedSubTab);
        const currentSubTabId =
            templates?.find(x => x.id === propsSubTabId)?.id // Check id from all templates, template will be opened if not currently open.
            ?? parseTemplateId(currentTab?.items?.[0] ? currentTab?.items?.[0].id : ""); // Take first open template if props template is not found.

        this.setState({
            tabs,
            templates,
        });

        await this.getCustomFields();
        await this.getData({
            tab: currentTab?.id,
            templateId: currentSubTabId,
            initial: true
        });
    }

    getLocalStorageKey = (key: string) => {
        const { userObject: { usersId } } = this.context;

        return `new_reports_${usersId}_${key}`;
    }

    getCustomFields = async () => {
        const resp = (await DataHandler.get({ url: 'new_reports/customfields' })) as CustomFieldsData;

        this.setState({ customFields: resp });
    }

    getAutoCompleteData = async (tab) => {
        DataHandler.get({ url: 'new_reports/autoCompleteData' }).done(resp => {
            const currencies = (resp.currencies || []).map(c => { return { name: c, id: c, label: c } });
            this.setState({ currencies });
        });

        const responses = await Promise.all([
            DataHandler.get({ url: 'subjects/companies' }),
            DataHandler.get({ url: `subjects/user_groups/all`, type: "teams" }),
            DataHandler.get({ url: `new_reports/templates/users/${tab}` })
        ]);
        const companies = responses[0] || [];
        const groups = responses[1] || [];
        const users = responses[2] || [];

        this.setState({ autoCompleteData: { companies, groups, users } });
    }

    getAutocompleteUsers = (tab) => {
        const { autoCompleteData } = this.state;
        DataHandler.get({ url: `new_reports/templates/users/${tab}` }).done(users => {
            this.setState({ autoCompleteData: { ...autoCompleteData, users } });
        })
    }

    translateColumns = (cols: ColDef[]): ColDef[] => {
        const translatedCols = cols.map((c: ColDef) => {
            if (!c.field || c?.field?.indexOf("_custom_") > -1) {
                return c;
            }

            c.headerName = c.headerName
                ? this.tr(c.headerName)
                : c.headerName;

            return c;
        });

        translatedCols.sort((a: ColDef, b: ColDef) => {
            if ((a.headerName || "") > (b.headerName || "")) return 1;
            if ((b.headerName || "") > (a.headerName || "")) return -1;
            return 0;
        })

        return translatedCols;
    }

    // TODO: Refactor to smaller functions.
    // TODO: Really refactor this ASAP because it's a trainwreck.
    getData = async (params: ChangeTabParams) => {
        const ts: number            = timestamp();
        this.lastDataFetchTimestamp = ts;

        const { functions: { updateView } } = this.context;
        const { 
            template: currentlyLoadedTemplate, 
            templates, 
            openTemplates, 
            lastOpenedReport 
        } = this.state;

        const currentFilterState = this?.grid?.current?.getCurrentFilterState() ?? this.state.filterState;

        const { selectedTab, selectedSubTab, updateViewOriginPoint } = this.props;
        const { templateId, refresh, initial, changeOriginPoint } = params;
        const { selectedTab: stateTab } = this.state;
        let { isLoading: currentLoadingState } = this.state;

        let tab = (params.tab ?? selectedTab) || 'invoicing';
        let templateData = currentlyLoadedTemplate;
        let changingTemplate = false;

        // Changing template
        if((params.templateId !== undefined && params.templateId !== null) 
           && (templateData?.id !== params.templateId || params.templateId !== this.previousTemplateId)) {
            changingTemplate = true;

            if (!currentLoadingState) {
                currentLoadingState = true;
            }

            const updatedOpenTemplates = templateId && !openTemplates.includes(templateId) ? [...openTemplates, templateId] : openTemplates;

            this.setState({
                isLoading: currentLoadingState,
                tabs: this.updateTabs(templates, updatedOpenTemplates),
                openTemplates: updatedOpenTemplates,
            });

            writeLocalStorage(this.OPEN_TEMPLATES_KEY, updatedOpenTemplates);

            // If new tamplate is saved, get data from template in state. So shared_to is correctly undefined and gets fetched when save dialog is opened.
            templateData = currentlyLoadedTemplate?.isNew ? currentlyLoadedTemplate : await getTemplateData(templateId);            

            if (templateData) {
                templateData.isNew = false;

                // Make sure correct tab is selected
                tab = reportTypeToTab(templateData.report_type);
            }
        }

        let rows: any[] = [];

        // TODO: Fix.
        const isDataLoaded = false;

        // const isDataLoaded = this.state.datas[tab] !== undefined 
            // && this.state.columnDefs?.[tab] !== undefined 
            // && !refresh
            // TODO: A way to check whether the time span of the
            // previous template differs from the current one.
            // && true === false;

        const switchingTab = stateTab !== tab;

        let cols: ColDef[] = [];

        if(switchingTab || this.state.columns.length === 0) {
            cols = this.state.columnDefs[tab] ?? [];
        } else if(this.state.columns?.length > 0 && !changingTemplate) {
            cols = this.state.columns;
        }

        const expectedSubTab = templateData ? `template_${templateData.id}` : '';

        // Switch to currect tab / subtab
        if (tab !== selectedTab || (expectedSubTab && expectedSubTab !== selectedSubTab) || (!expectedSubTab && selectedSubTab)) {
            this.setState({
                template: templateData,
            }, () => {
                updateView({
                    selectedTab: tab,
                    selectedSubTab: templateData ? `template_${templateData.id}` : '',
                    nonUrlParams: { updateViewOriginPoint: changeOriginPoint || updateViewOriginPoint || "" }
                });
            });

            return;
        }

        // TODO: Fix.
        if (!isDataLoaded) {
            currentLoadingState = true;
        }

        // TODO: Consider await _setState.
        this.setState({
            selectedTab: tab,
            columns: cols,
            rows,
            isLoading: currentLoadingState,
            template: templateData,
            switchingTab,
        }, () => !initial && this.getAutocompleteUsers(tab)); // If not initial fetch, get users for selected tab. Initial fetch gets users in getAutoCompleteData.

        const nextFilterState = switchingTab || changingTemplate 
            ? templateData?.data_json?.filterState ?? defaultFilterState
            : currentFilterState;

        const currentReport = tab + "_" + (templateData?.id || 0);

        let startDate: Date | null       = null;
        let endDate: Date | null         = null;
        let relativeRange: string | null = this.state.relativeReportDateRangeSelection;

        let materialStartDate: Date | null       = null;
        let materialEndDate: Date | null         = null;
        let materialRelativeRange: string | null = this.state.relativeMaterialDateRangeSelection;

        // When changing template, we need to check
        // the template for any date spans, and 
        // apply them if necessary.
        //
        // dateSpan = report date span
        // materialDateSpan = material date span,
        // and same for relative date spans.
        //
        // Relative date spans take precedence over
        // absolute date spans.
        if(changingTemplate) {
            if((templateData?.data_json?.dateSpan?.start && templateData?.data_json?.dateSpan?.end) 
               || (templateData?.date_span?.start && templateData?.date_span?.end)) {
               startDate = new Date(templateData?.data_json?.dateSpan?.start ?? templateData?.date_span?.start ?? new Date());
               endDate   = new Date(templateData?.data_json?.dateSpan?.end ?? templateData?.date_span?.end ?? new Date());
            }

            if((templateData?.data_json?.materialDateSpan?.start && templateData?.data_json?.materialDateSpan?.end)
                || (templateData?.material_date_span?.start && templateData?.material_date_span?.end)) {
               materialStartDate = new Date(templateData?.data_json?.materialDateSpan?.start ?? templateData?.material_date_span?.start ?? new Date());
               materialEndDate   = new Date(templateData?.data_json?.materialDateSpan?.end ?? templateData?.material_date_span?.end ?? new Date());
            }

            if(templateData?.data_json?.relativeRange || templateData?.relative_range) {
                relativeRange = templateData?.data_json?.relativeRange ?? templateData?.relative_range ?? null;

                startDate = null;
                endDate   = null;
            } else {
                relativeRange = null;
            }

            if(templateData?.data_json?.materialRelativeRange || templateData?.material_relative_range) {
                materialRelativeRange = templateData?.data_json?.materialRelativeRange ?? templateData?.material_relative_range ?? null;

                materialStartDate = null;
                materialEndDate   = null;
            } else {
                materialRelativeRange = null;
            }
        } else if(!changingTemplate) {
            startDate         = this.state.reportDateRange.startDate;
            endDate           = this.state.reportDateRange.endDate;
            materialStartDate = this.state.materialDateRange.startDate;
            materialEndDate   = this.state.materialDateRange.endDate;
        } else {
            startDate         = null;
            endDate           = null;
            materialStartDate = null;
            materialEndDate   = null;
        }

        const newState: Pick<State, 'rows' | 'columns' | 'columnDefs' | 'datas' |  'isLoading' | 'selectedTab' | 'switchingTab' | 'isApplyingFilterState' 
            | 'lastOpenedReport' | 'materialDateRange' | 'relativeMaterialDateRangeSelection' | 'reportDateRange' | 'relativeReportDateRangeSelection'> = {
            rows,
            columns: cols,
            columnDefs: this.state.columnDefs,
            datas: { ...this.state.datas },
            // Moved to the later setState. Doesn't work here anymore.
            // filterState: nextFilterState,
            // isApplyingFilterState: !!nextFilterState && nextFilterState !== currentFilterState,
            // Eeh.
            isApplyingFilterState: true,
            isLoading: true,
            switchingTab: false,
            selectedTab: tab,
            lastOpenedReport: currentReport,
            materialDateRange: {
                ...this.state.materialDateRange,
                startDate: materialStartDate,
                endDate: materialEndDate,
            },
            relativeMaterialDateRangeSelection: materialRelativeRange,
            reportDateRange: {
                ...this.state.reportDateRange,
                startDate: startDate,
                endDate: endDate,
            },
            relativeReportDateRangeSelection: relativeRange
        };

        // Apply the potential date range selection first.
        await this._setState(newState);

        // Here you can be pretty sure that this.state == newState.
        const restOfTheState: State = {
            ...this.state,
            filterState: nextFilterState,
            isApplyingFilterState: false
        };

        if (isDataLoaded) 
        {
            rows = this.state.datas[tab];
            cols = this.state.columnDefs[tab];
        }
        else
        {
            const request = this.getTabReportData(tab);

            ({
                cols,
                rows
            } = await request);

            // TODO: Fix isDataLoaded. 
            // Cached data should not be used, but for columns it's necessary.
            if(this.state.columnDefs?.[tab] !== undefined && !changingTemplate) {
                cols = this.state.columnDefs?.[tab];
            }

            // TODO: Move
            if(this.state.relativeReportDateRangeSelection) {
                const span = await DataHandler.get({ url: `new_reports/relative_timespan/${this.state.relativeReportDateRangeSelection}` });

                restOfTheState.relativeReportDateRangeAbsolute = {
                    startDate: new Date(span.start),
                    endDate: new Date(span.end)
                }
            } else {
                restOfTheState.relativeReportDateRangeAbsolute = null;
            }

            // TODO: Move
            if(this.state.relativeMaterialDateRangeSelection) {
                const span = await DataHandler.get({ url: `new_reports/relative_timespan/${this.state.relativeMaterialDateRangeSelection}` });

                restOfTheState.relativeMaterialDateRangeAbsolute = {
                    startDate: new Date(span.start),
                    endDate: new Date(span.end)
                }
            } else {
                restOfTheState.relativeMaterialDateRangeAbsolute = null;
            }

            // TODO:
            // @ts-ignore
            restOfTheState.datas[tab] = rows;
            restOfTheState.columns    = cols;
            restOfTheState.columnDefs = {
                ...restOfTheState.columnDefs,
                [tab]: cols,
            };
        }

        restOfTheState.rows = rows;

        if (switchingTab) { // Don't switch columns to prevent flicker
            restOfTheState.columns = cols;
        }

        if (lastOpenedReport != currentReport) {
            this.context.functions.sendMixpanelEvent('open_report', {
                "report_module": tab,
                "report_name": templateData?.name || "",
                "origin_point": this.props.updateViewOriginPoint || ""
            });
        }

        // If this happens, a later call has set 
        // the timestamp, and we shouldn't 
        // finish setting the data to prevent
        // ending up with the wrong data visible.
        if(ts < this.lastDataFetchTimestamp) {
            return;
        }

        this.setState(restOfTheState, () => {
            setTimeout(() => this.setState({ 
                isLoading: false,
                isApplyingFilterState: false
            }), 800);
        });
    }

    getDataDbounced = debounce(this.getData, 500);

    getTabReportData = async (tab: string): Promise<{ rows: any[]; cols: ColDef[] }> => {
        const {
            materialDateRange: {
                startDate: materialStartDate,
                endDate: materialEndDate
            },
            reportDateRange: {
                startDate: reportStartDate,
                endDate: reportEndDate
            },
            customFields,
            selectedCurrency,
            relativeMaterialDateRangeSelection,
            relativeReportDateRangeSelection
        } = this.state;

        let cols: ColDef[];
        let rows;

        const extraParams: Record<string, string> = {
            selectedCurrency: selectedCurrency,
            materialStartDate: materialStartDate ? format(materialStartDate, "YYYY-MM-DD") : "",
            materialEndDate: materialEndDate ? format(materialEndDate, "YYYY-MM-DD") : "",
            materialRelativeRange: materialStartDate === null && materialEndDate === null 
                ? relativeMaterialDateRangeSelection ?? "" 
                : "",
            startDate: reportStartDate ? format(reportStartDate, "YYYY-MM-DD") : "",
            endDate: reportEndDate ? format(reportEndDate, "YYYY-MM-DD") : "",
            relativeRange: reportStartDate === null && reportEndDate === null 
                ? relativeReportDateRangeSelection ?? "" 
                : ""
        };

        extraParams['selectedCurrency'] = selectedCurrency;

        switch (tab) {
            case 'workinghours':
                cols = getWorkinghourColumns({
                    customFields,
                    checkPrivilege: this.context.functions.checkPrivilege,
                    tr: this.tr,
                    settingsContext: this.context,
                });
                rows = await this.reportService.getHoursReports(extraParams);
                cols = await handleDimensionColumns({
                    data: rows,
                    columns: cols,
                    settingsContext: this.context,
                    userPrefix: "user"
                });

                break;
            case 'costs':
                rows = (await DataHandler.get({ url: "new_reports/costs", ...extraParams })).reports.rows;
                cols = getCostsColumns({
                    customFields,
                    netvisorActive: !!this.context.addons?.netvisor,
                    procountorActive: !!this.context.addons?.procountor,
                    checkPrivilege: this.context.functions.checkPrivilege,
                    tr: this.tr,
                    settingsContext: this.context,
                });
                cols = await handleDimensionColumns({
                    data: rows,
                    columns: cols,
                    settingsContext: this.context,
                    userPrefix: "created_by"
                });

                break;
            case 'projects':
                rows = (await DataHandler.get({ url: "new_reports/projects", ...extraParams })).reports.rows;
                cols = getProjectDataColumns({
                    customFields,
                    // TODO: Change to reportStartDate etc.
                    startDate: materialStartDate,
                    endDate: materialEndDate,
                    dateFormat: this.context.userObject.dateFormat,
                    tr: this.tr,
                    settingsContext: this.context,
                });
                cols = await handleDimensionColumns({
                    data: rows,
                    columns: cols,
                    settingsContext: this.context,
                    userPrefix: "user",
                    dimensionColumnTypes: ["project"]
                });

                break;
            case 'forecast':
                rows = await this.reportService.getForecastReports(extraParams);
                cols = getForecastColumns({
                    customFields,
                    // TODO: Change to reportStartDate etc.
                    startDate: materialStartDate,
                    endDate: materialEndDate,
                    dateFormat: this.context.userObject.dateFormat,
                    tr: this.tr,
                    settingsContext: this.context,
                });
                cols = await handleDimensionColumns({
                    data: rows,
                    columns: cols,
                    settingsContext: this.context,
                    userPrefix: "user",
                    dimensionColumnTypes: ["project"]
                });
                break;
            case 'scheduled_invoicing':
                rows = await this.reportService.getScheduledInvoicingReports(extraParams);
                cols = getScheduledInvoicingColumns({
                    customFields,
                    netvisorActive: !!this.context.addons?.netvisor,
                    procountorActive: !!this.context.addons?.procountor,
                    checkPrivilege: this.context.functions.checkPrivilege,
                    tr: this.tr,
                    settingsContext: this.context,
                });
                cols = await handleDimensionColumns({
                    data: rows,
                    columns: cols,
                    settingsContext: this.context,
                    userPrefix: "creator",
                    dimensionColumnTypes: ["project"]
                });

                break;
            case 'accounts':
                // Not used for accounts reports, but they 
                // have default values we don't want to send.
                extraParams.startDate = "";
                extraParams.endDate   = "";

                rows = await this.reportService.getAccountReports(extraParams);
                cols = getAccountDataColumns({
                    customFields,
                    startDate: materialStartDate,
                    endDate: materialEndDate,
                    dateFormat: this.context.userObject.dateFormat,
                    tr: this.tr,
                    settingsContext: this.context,
                });

                break;
            default:
            case 'invoicing':
                rows = await this.reportService.getInvoicingReports(extraParams);
                cols = getInvoicingColumns({
                    customFields,
                    netvisorActive: !!this.context.addons?.netvisor,
                    procountorActive: !!this.context.addons?.procountor,
                    checkPrivilege: this.context.functions.checkPrivilege,
                    tr: this.tr,
                    settingsContext: this.context,
                });
                cols = await handleDimensionColumns({
                    data: rows,
                    columns: cols,
                    settingsContext: this.context,
                    userPrefix: "creator"
                });

                break;
           case 'activities':
                    rows = await this.reportService.getActivitiesReports(extraParams);
                    cols = getActivitiesColumns({
                        customFields,
                        startDate: materialStartDate,
                        endDate: materialEndDate,
                        dateFormat: this.context.userObject.dateFormat,
                        tr: this.tr,
                        settingsContext: this.context,
                        checkPrivilege: this.context.functions.checkPrivilege,
                        getActivityTypeIcon: this.getActivityTypeIcon
                    });
                    cols = await handleDimensionColumns({
                        data: rows,
                        columns: cols,
                        settingsContext: this.context,
                        userPrefix: "user",
                        dimensionColumnTypes: ["project"]
                    });
                    break;
           case 'sales':
                    rows = await this.reportService.getSalesReports(extraParams);
                    cols = getSalesColumns({
                        customFields,
                        startDate: materialStartDate,
                        endDate: materialEndDate,
                        dateFormat: this.context.userObject.dateFormat,
                        tr: this.tr,
                        settingsContext: this.context,
                        checkPrivilege: this.context.functions.checkPrivilege,
                    });
                    cols = await handleDimensionColumns({
                        data: rows,
                        columns: cols,
                        settingsContext: this.context,
                        userPrefix: "user",
                        dimensionColumnTypes: ["project"]
                    });
                    break;
        }

        const hiddenFields = await DataHandler.get({ url: "new_reports/hidden_fields", tab: tab });

        cols = (cols || []).filter(c => !hiddenFields.find(h => h == c.field));
        cols = this.translateColumns(cols);

        return {
            rows: rows,
            cols: cols
        };
    }

    getCustomReports = async () => {
        const url = "new_reports/custom";
        const resp = await DataHandler.get({ url: url });
        const tabs = [...this.state.tabs];
        resp.custom_reports.forEach(el => {
            tabs[0].items?.push({
                id: el.id,
                label: this.tr(el.name),
                //is_custom: true
            });
        });
        this.setState({ custom_reports: resp.custom_reports, tabs: tabs });
    }

    onReportDateChange = (event) => {
        const { 
            startDate, 
            endDate,
            relative
        } = event.selection;

        const fetchDataAfter = relative 
            || (startDate !== endDate) 
            || (event?.singleDate ?? false)
            || (!startDate && !endDate && !relative);

        this.setState({
            reportDateRange: {
                startDate: !relative ? startDate : null,
                endDate: !relative ? endDate : null,
                key: "selection"
            },
            relativeReportDateRangeSelection: relative ?? null,
            reportDateRangeChanged: true,
        }, () => {
            if(!fetchDataAfter) {
                return;
            }

            this.getDataDbounced({
                refresh: true
            });
        });
    }

    onReportDateChangeClose = () => {
        if (!this.state.reportDateRangeChanged) {
            return;
        }

        this.setState({ reportDateRangeChanged: false });
    }

    onReportDateInputChange = (dateType, date) => {
        const key = dateType === "start" 
            ? "startDate" 
            : "endDate";

        this.setState({
            // @ts-ignore
            reportDateRange: {
                ...this.state.reportDateRange,
                [key]: new Date(format(date, "YYYY-MM-DD"))
            },
            reportDateRangeChanged: true,
        }, () => {
            this.getDataDbounced({
                refresh: true,
            });
        });
    }

    onMaterialDateChange = (event) => {
        const { 
            startDate, 
            endDate,
            relative
        } = event.selection;

        const fetchDataAfter = relative 
            || (startDate !== endDate) 
            || (event?.singleDate ?? false) 
            || (!startDate && !endDate);

        this.setState({
            materialDateRange: {
                startDate: !relative ? startDate : null,
                endDate: !relative ? endDate : null,
                key: "selection"
            },
            relativeMaterialDateRangeSelection: relative ?? null,
            materialDateRangeChanged: true,
        }, () => {
            if(!fetchDataAfter) {
                return;
            }

            this.getDataDbounced({
                refresh: true,
            });
        });

    }

    onMaterialDateChangeClose = () => {
        if(!this.state.materialDateRangeChanged) {
            return;
        }

        this.setState({ materialDateRangeChanged: false });

        this.getDataDbounced({
            refresh: true,
        });
    }

    onMaterialDateInputChange = (dateType, date) => {
        const key = dateType === "start" 
            ? "startDate" 
            : "endDate";

        this.setState({
            // @ts-ignore
            materialDateRange: {
                ...this.state.materialDateRange,
                [key]: new Date(format(date, "YYYY-MM-DD"))
            },
            materialDateRangeChanged: true,
        }, () => {
            this.getDataDbounced({
                refresh: true,
            });
        });
    }

    switchCurrency = (currency: string) => {
        const dataTypeDefinitions = getDataTypeDefinitions(this.context, { selectedCurrency: currency });

        this.setState({
            dataTypeDefinitions,
            selectedCurrency: currency,
        }, () => this.getData({
            refresh: true,
        }));
    }

    openGallery = () => {
        this.setState({galleryOpen: true});
    }

    closeGallery = () => {
        this.setState({galleryOpen: false});
    }

    getActivityTypeIcon = (data: any) => {
        return <img alt="" src={data?.old_icon_set > 0 ? data?.type_icon : this.activityTypeIcons[data?.type_icon]} />
    }

    renderSaveTemplateButton = () => {
        return <>
            <button
                disabled={this.state.isLoading}
                className={"save-template-button"}
                onClick={this.saveTemplate}
            >
                {this.tr("Save report")}
            </button>

            <Tooltip title={this.tr("Report gallery")} arrow classes={{ tooltip: 'darkblue-tooltip' }}>
                <button className={"save-template-button"} onClick={this.openGallery}>
                    <GridView />
                </button>
            </Tooltip>
        </>
    }

    // Uses the same dialog as saveTemplate,
    // but passes additional props that modify
    // the dialog's behavior.
    createTemplate = () => {
        this.setState({
            createReportDialogOpen: true
        });
    }

    saveTemplate = () => {
        const { 
            selectedCurrency,
            materialDateRange,
            relativeMaterialDateRangeSelection,
            reportDateRange,
            relativeReportDateRangeSelection
        } = this.state;

        if (!this.grid.current) {
            return; // Grid not initialized
        }

        const state = this.grid.current.getCurrentFilterState();

        const filterData: FilterDataJson = {
            filterState: state,
            selectedCurrency,
        }

        if(materialDateRange.startDate && materialDateRange.endDate) {
            filterData.materialDateSpan = {
                start: format(materialDateRange.startDate, "YYYY-MM-DD"),
                end: format(materialDateRange.endDate, "YYYY-MM-DD")
            };
        }

        if(reportDateRange.startDate && reportDateRange.endDate) {
            filterData.dateSpan = {
                start: format(reportDateRange.startDate, "YYYY-MM-DD"),
                end: format(reportDateRange.endDate, "YYYY-MM-DD")
            };
        }

        if(!materialDateRange.startDate && !materialDateRange.endDate
            && relativeMaterialDateRangeSelection) {
                filterData.materialRelativeRange = relativeMaterialDateRangeSelection;
        }

        if(!reportDateRange.startDate && !reportDateRange.endDate
            && relativeReportDateRangeSelection) {
                filterData.relativeRange = relativeReportDateRangeSelection;
        }

        this.setState({ 
            saveTemplateDialogOpen: true,
            saveTemplateData: filterData,
            saveTemplateDialogData: null
        });
    }

    openTemplateEdit = (template: TemplateData) => {
        if (!this.grid.current) {
            return; // Grid not initialized
        }

        this.setState({
            saveTemplateDialogOpen: true,
            saveTemplateData: undefined,
            saveTemplateDialogData: {
                noFilterStateSave: true,
                template
            }
        });
    }

    openTemplateDeleteConfirmation = ( template: TemplateData ) => {
        this.context.functions.showConfirmationDialog({
            header: this.tr('Delete report?'),
            warning: this.tr('Are you sure you want to delete report: ${name}? The report cannot be accessed by anyone after removal.', {name: template.name}),
            confirmText: this.tr('Delete'),
            onConfirm: () => this.deleteTemplate(template),
        });
    }

    deleteTemplate = ( template: TemplateData ) => {
        const { enqueueSnackbar } = this.props;

        DataHandler.delete({ url: `new_reports/templates/${template.id}` }).done(resp => {
            this.templateDeleted(template);

            enqueueSnackbar && enqueueSnackbar(this.tr('Template deleted successfully!'), {
                variant: 'success'
            });
        })
            .fail(err => {
                enqueueSnackbar && enqueueSnackbar(this.tr('Error in deleting template!'), {
                    variant: 'success'
                });
            })

    }

    templateDeleted = (template: TemplateData) => {
        const { templates: currectTemplates, selectedTab } = this.state;
        const templates = [...currectTemplates];

        this.context.functions.sendMixpanelEvent('delete_report', {
            "report_module": selectedTab,
            "report_name": template?.name || ""
        });

        const index = templates.findIndex(x => x.id === template.id);
        templates.splice(index, 1);

        this.setState({ templates }, () => {
            this.closeTab({
                id: "template_" + template.id,
                label: template.name
            });
        });
    }

    onSelectTemplate = (template: TemplateListItem) => {
        this.setState({ galleryOpen: false });

        this.getData({
            templateId: template.id,
            changeOriginPoint: "report_gallery"
        });
    }

    onUpdateTemplate = (template: TemplateListItem, update: Partial<TemplateListItem>) => {
        const { templates: currectTemplates, openTemplates: currentOpenTemplates, template: currentTemplate } = this.state;
        const index = currectTemplates.findIndex(x => x.id === template.id);

        if (index < 0) {
            return;
        }

        const templates = [...currectTemplates];

        templates[index] = {
            ...templates[index],
            ...update,
        };

        const isCurrentlyOpen = template.id == currentTemplate?.id;

        const openTemplates = isCurrentlyOpen ? currentOpenTemplates : currentOpenTemplates.filter(x => x !== template.id)

        this.setState({
            templates,
            openTemplates,
            tabs: this.updateTabs(templates, openTemplates),
        });
    }


    closeTab = (tab: Tab) => {
        const { selectedTab, selectedSubTab } = this.props;
        const { templates, openTemplates, tabs: currentTabs } = this.state;

        const currentTab = currentTabs.find(x => x.id === selectedTab);
        const currentSubTab = currentTab?.items?.find(x => x.id === selectedSubTab);
        const templateId = parseTemplateId(tab.id);
        const isCurrent = selectedSubTab === tab.id;

        if (templateId) {
            const updated = openTemplates.filter(x => x !== templateId);

            this.setState({
                tabs: this.updateTabs(templates, updated),
                openTemplates: updated,
            }, () => {
                // Switch to next tab
                if (isCurrent && currentTab && currentTab.items && currentSubTab) {
                    const currentPos = currentTab.items.findIndex(x => x.id === currentSubTab.id)
                    const nextTab = currentTab.items[currentPos + 1] ?? currentTab.items[currentPos - 1];

                    this.getData({
                        templateId: parseTemplateId(nextTab?.id ?? null),
                        changeOriginPoint: "tabbar_tab"
                    });
                }
            });

            writeLocalStorage(this.OPEN_TEMPLATES_KEY, updated);
        }
    }

    /**
     * Updates templates after save. Template is new if it's not found from existing templates.
     * @param updatedTemplate Template with updated data
     * @param detailsEdited Only template details were edited from gallery.
     * @param isNew New template created.
     */
    templateSaved = (updatedTemplate: TemplateData, detailsEdited = false, isNew = false) => {
        const { functions: { updateView } } = this.context;
        const { 
            templates: currectTemplates, 
            openTemplates, 
            template, 
            selectedTab 
        } = this.state;

        const templates = [...currectTemplates];

        const index = templates.findIndex(x => x.id === updatedTemplate.id);

        const updatedOpenTemplates = updatedTemplate.id && !openTemplates.includes(updatedTemplate.id) ? [...openTemplates, updatedTemplate.id] : openTemplates;

        if (index === -1) {
            templates.push({
                id: updatedTemplate.id,
                name: updatedTemplate.name,
                description: updatedTemplate.description,
                is_favorite: false,
                is_hidden: false,
                is_own: true,
                is_shared_to_others: false,
                order_nr: 0,
                report_type: updatedTemplate.report_type,
                shared_by_user: "",
                shared_date: "",
                users_id: Number(this.context.userObject.usersId),
                is_default: false,
                shared_to: updatedTemplate.shared_to,
            });
        } else {
            templates[index] = {
                ...templates[index],
                name: updatedTemplate.name,
                description: updatedTemplate.description,
                shared_to: updatedTemplate.shared_to
            };
        }

        const nullDateSelection: {
            key: "selection";
            startDate: Date | null;
            endDate: Date | null;
        } = {
            key: "selection",
            startDate: null,
            endDate: null
        };

        const reportDateRange = updatedTemplate?.date_span ? {
            ...this.state.reportDateRange,
            startDate: updatedTemplate?.date_span?.start ?? this.state.reportDateRange.startDate,
            endDate: updatedTemplate?.date_span?.end ?? this.state.reportDateRange.endDate,
        } : this.state.reportDateRange;

        const materialDateRange = updatedTemplate?.material_date_span ? {
            ...this.state.materialDateRange,
            startDate: updatedTemplate?.material_date_span?.start ?? this.state.materialDateRange.startDate,
            endDate: updatedTemplate?.material_date_span?.end ?? this.state.materialDateRange.endDate,
        } : this.state.materialDateRange;

        const relativeRange         = updatedTemplate?.relative_range ?? null;
        const relativeMaterialRange = updatedTemplate?.material_relative_range ?? null;

        this.setDirty(false);

        this.setState({
            templates, 
            template: updatedTemplate,
            tabs: this.updateTabs(templates, updatedOpenTemplates),
            openTemplates: updatedOpenTemplates,
            reportDateRange: !relativeRange ? reportDateRange : nullDateSelection,
            materialDateRange: !relativeMaterialRange ? materialDateRange : nullDateSelection,
            relativeReportDateRangeSelection: relativeRange,
            relativeMaterialDateRangeSelection: relativeMaterialRange
        }, () => {
            !detailsEdited && updateView({
                selectedSubTab: `template_${updatedTemplate.id}`,
            });
        });

        const mixPanelEvent = isNew
            ? 'create_report'
            : (detailsEdited
                ? 'edit_report_details'
                : 'edit_report');

        this.context.functions.sendMixpanelEvent(mixPanelEvent, {
            "report_module": selectedTab,
            "report_name": updatedTemplate?.name || "",
        });

        writeLocalStorage(this.OPEN_TEMPLATES_KEY, updatedOpenTemplates);
    }

    updateTemplateSharedTo = (data: SaveTemplateDialogTemplate) => {
        const { templates: currectTemplates } = this.state;
        const templates = [...currectTemplates];

        const index = templates.findIndex(x => x.id === data.id);
        if (index === -1) {
            return;
        }

        templates[index] = {
            ...templates[index],
            shared_to: data.shared_to
        };

        this.setState({ templates });
    }

    onFilterStateStartApplying = () => {
        this.setState({isApplyingFilterState: true});
    }

    onFilterStateApplied = () => {
        this.setState({isApplyingFilterState: false});
    }

    saveTemplateDialogShouldBeShown = (): boolean => {
        return Boolean(this.state.createReportDialogOpen
            || (this.state.saveTemplateDialogOpen && (this.state.saveTemplateData || this.state.saveTemplateDialogData)));
    }

    getSaveTemplateDialogProps = (): SaveTemplateDialogProps => {
        const {
            template, 
            selectedTab, 
            saveTemplateData, 
            saveTemplateDialogData,
            createReportDialogOpen,
            saveTemplateDialogOpen,
            materialDateRange: {
                startDate: materialStartDate,
                endDate: materialEndDate
            },
            reportDateRange: {
                startDate: reportStartDate,
                endDate: reportEndDate
            },
            relativeReportDateRangeSelection,
            relativeMaterialDateRangeSelection,
        } = this.state;

        return saveTemplateDialogOpen ? {
            onClose: () => this.setState({ saveTemplateDialogOpen: false }),
            onCancel: (data?: SaveTemplateDialogTemplate) => {
                // Update shared_to data to template when dialog is closed and edit opened from gallery. 
                // So that shared_to data is not fetched again everytime the edit is opened.
                if (data && Number(data?.id) > 0 && saveTemplateDialogData) {
                    this.updateTemplateSharedTo(data);
                }
            } ,
            onSave: (template, onlyEdit, isNew) => this.templateSaved(template, onlyEdit, isNew),
            enqueueSnackbar: this.props.enqueueSnackbar,
            filterState: saveTemplateDialogData?.noFilterStateSave ? undefined : saveTemplateData,
            selectedTab: selectedTab,
            template: saveTemplateDialogData?.template || template,
            autoCompleteData: this.state.autoCompleteData,
            noFilterStateSave: saveTemplateDialogData?.noFilterStateSave || false,
            onlyEdit: saveTemplateDialogData ? true : false,
            dateSpan: {
                start: reportStartDate ? format(reportStartDate, "YYYY-MM-DD") : undefined,
                end: reportEndDate ? format(reportEndDate, "YYYY-MM-DD") : undefined,
            },
            materialDateSpan: {
                start: materialStartDate ? format(materialStartDate, "YYYY-MM-DD") : undefined,
                end: materialEndDate ? format(materialEndDate, "YYYY-MM-DD") : undefined,
            },
            relativeDateSpan: relativeReportDateRangeSelection,
            materialRelativeDateSpan: relativeMaterialDateRangeSelection,
            showClearButtonForReportDateRange: ['activities', 'sales'].indexOf(selectedTab) > -1,
            noDateSpanSelectionPlaceholder: this.tr("All time")
        } : {
            onClose: () => this.setState({ createReportDialogOpen: false }),
            onCancel: () => this.setState({ createReportDialogOpen: false }),
            onSave: (template: TemplateData, onlyEdit?: boolean, isNew?: boolean) => {
                // TODO: A 'reset' function in AgGridWrapper.
                this.grid.current?.gridApi?.setFilterModel(null);
                this.grid.current?.columnApi?.resetColumnState();
                this.grid.current?.columnApi?.setPivotMode(false);

                this.templateSaved(template, onlyEdit, isNew);
            },
            selectedTab: selectedTab,
            autoCompleteData: this.state.autoCompleteData,
            title: this.state.createReportDialogOpen ? this.tr("Create report") : undefined,
            subtitle: this.state.createReportDialogOpen 
                ? this.tr("Enter the details below to create a new report template. After creation you can configure the report to your liking and save the changes.") 
                : undefined,
            saveButtonText: this.tr("Create report"),
            hideNotifications: true,
            enqueueSnackbar: this.props.enqueueSnackbar,
            showReportDateRangePicker: selectedTab !== "accounts",
            reportDateRangeTooltipContent: this.getTooltipText(selectedTab),
            showMaterialDateRangePicker: false,
            materialRelativeDateSpan: null,
            materialDateSpan: null,
            dateSpan: {
                start: format(startOfMonth(new Date()), "YYYY-MM-DD"),
                end: format(endOfMonth(new Date()), "YYYY-MM-DD"),
            },
            showClearButtonForReportDateRange: ['activities', 'sales'].indexOf(selectedTab) > -1,
            noDateSpanSelectionPlaceholder: this.tr("All time"),
        }
    }

    getTooltipText = (tab: string): string | null => {
        const translations = {
            invoicing: this.tr("Report data is filtered by invoice date"),
            scheduled_invoicing: this.tr("Report data is filtered by invoice date"),
            workinghours: this.tr("Report data is filtered by workhour date"),
            costs: this.tr("Report data is filtered by cost date"),
            projects: this.tr("If a project was active during the selected period, it will show up"),
            sales: this.tr("Report data is filtered by sales quote creation date or project status change date."),
            activities: this.tr("Report data is filtered by the activity date."),
            forecast: this.tr("Report data is filtered to show data for the months of the selected timespan."),
        };

        return translations?.[tab] ?? null;
    }

    getActiveFilters = () => {
        const filterModel = this.grid.current && this.grid.current.getFilterModel();
        return filterModel;
    }

    summarizeWithAI = async (prompt: string) => {
        this.setState({ summaryLoading: true })
        const file = this.grid.current?.getExcelFile();
        if (file) {
            const promptString = !!prompt && prompt != "" ? `?prompt=${prompt}` : '';
            try {
              const response = await DataHandler.file({url: `new_reports/summarize${promptString}`}, file);
              this.setState({ summary: response.summary, summaryLoading: false });
            } catch (error) {
              console.error('Error uploading file:', error);
              this.setState({ summaryLoading: false });
            }
          }
    }

    // Leaving this here for future use purposes, hidden for now
    getTabInfoButtonProps = (selectedTab, isSubTab) => {
        if (isSubTab) return undefined;
        switch (selectedTab) {
            default:
                return {
                    title: this.tr('How New Reports work'),
                    methods: {
                        article:
                            this.context.userObject.language == 'fi'
                                ? 'https://psahelpcenter.heeros.com/hc/fi/articles/18349062123922'
                                : 'https://psahelpcenter.heeros.com/hc/en-us/articles/18349062123922',
                        flow: this.context.userObject.language == 'fi' ? 'd85171b7-74ac-4736-b52c-fa78d0a471c6' : '84b6f381-c655-4500-aecd-a86dca4f1171',
                        video: this.context.userObject.language == 'fi' ? 'https://www.youtube.com/embed/Nw8Hi6_dL5c?si=hhKvKxAoJtsC7v7d' : undefined
                    }
            }
        }
    }

    render() {
        const {
            tabs,
            rows, 
            columns, 
            reportDateRange,
            relativeReportDateRangeSelection,
            materialDateRange, 
            relativeMaterialDateRangeSelection,
            template, 
            templates,
            currencies, 
            selectedCurrency, 
            isLoading, 
            isApplyingFilterState,
            filterState, 
            dataTypeDefinitions, 
            selectedTab, 
            galleryOpen,
            summary,
            summaryLoading,
            activeFilterData
        } = this.state;
        const {
            selectedTab: visiblySelectedTab,
            selectedSubTab: visiblySelectedSubTab,
        } = this.props;
        const { userObject } = this.context;
        const { tr } = this;

        const activeFilterAmount = Object.keys(activeFilterData || {}).length;
        const activeFilterContent = (
            <div style={{ padding: '0 8px' }}>
                <p style={{ fontWeight: 'bold' }}><strong>{this.tr("Active filters")}:</strong></p>
                <ul>
                    {Object.keys(activeFilterData).map(key => {
                        const columnName = columns.find(c => c.field == key)?.headerName || key;
                        return <li>{columnName}</li>
                    })}
                </ul>
            </div>);

        const noCurrencyReports = ["activities"];

        return (
            <div className="taimer-view" id="new_reports-view">
                <WithTabs
                    selectedTab={visiblySelectedTab || ''}
                    selectedSubTab={visiblySelectedSubTab}
                    alwaysShowSubTabBar={["projects", "invoicing", "workinghours", "costs", "forecast", "scheduled_invoicing", "accounts", "activities", "sales"].indexOf(selectedTab) > -1}
                    tabs={tabs}
                    subActionItems={[
                        { 
                            content: <Add style={{ margin: 0 }} />,
                            outerProps: {
                                onClick: this.createTemplate
                            }
                        }
                    ]}
                    onTabClose={this.closeTab}
                    tabsAlwaysVisible
                    style={{ height: '100%' }}
                    subRightComponent={this.renderSaveTemplateButton()}
                    keepSelectedTab
                >
                    <div className='reportsToolbar'>
                        <div>
                        {["projects", "invoicing", "workinghours", "costs", "forecast", "scheduled_invoicing", "activities", "sales"].indexOf(selectedTab) > -1 && <div className='reportsToolbarItem'>
                            <div className='reportsToolbarItemName'>
                                <CalendarIcon />
                                {this.tr("Report timespan")}:
                            </div>
                            <div className='reportsToolbarItemEditor'>
                                <DateRangePicker
                                    mode="list"
                                    className="daterange"
                                    disabled={isLoading}
                                    ranges={[reportDateRange]}
                                    relativeRange={relativeReportDateRangeSelection}
                                    onChange={this.onReportDateChange}
                                    onInputChange={this.onReportDateInputChange}
                                    label={this.tr("Time span")}
                                    dateFormat={userObject.dateFormat}
                                    onClose={this.onReportDateChangeClose}
                                    allowClear
                                    showClearButton={['activities', 'sales'].indexOf(selectedTab) > -1}
                                    showRelative={true}
                                    inferInitialMonthsFromRange={true}
                                    hideCalendarIcon={true}
                                    visibleDateRange={this.state.relativeReportDateRangeAbsolute}
                                    noSelectionPlaceholder={['activities', 'sales'].indexOf(selectedTab) === -1 ? this.tr("No selection") : this.tr("All time")}
                                    relativeTimespanExplainer={this.tr("Choose from predefined relative timespans. If you save a report with a relative timespan, \
                                        the duration will remain the same and the date range will update accordingly each time you open it.")}
                                />
                            </div>
                            {this.getTooltipText(selectedTab) !== null && <StyledTooltip 
                                content={this.getTooltipText(selectedTab)}
                                placement="bottom">
                                <InfoOutlined 
                                    style={{
                                        width: 20
                                    }}
                                />
                            </StyledTooltip>}
                        </div>}
                        {["projects", "accounts"].indexOf(selectedTab) > -1 && <div className='reportsToolbarItem'>
                            <div className='reportsToolbarItemName'>
                                <CalendarIcon />
                                {this.tr("Material Period")}:
                            </div>
                            <div className='reportsToolbarItemEditor'>
                                <DateRangePicker
                                    mode="list"
                                    className="daterange"
                                    disabled={isLoading}
                                    ranges={[materialDateRange]}
                                    relativeRange={relativeMaterialDateRangeSelection}
                                    onChange={this.onMaterialDateChange}
                                    onInputChange={this.onMaterialDateInputChange}
                                    label={this.tr("Time span")}
                                    dateFormat={userObject.dateFormat}
                                    onClose={this.onMaterialDateChangeClose}
                                    allowClear
                                    showRelative={true}
                                    inferInitialMonthsFromRange={true}
                                    showClearButton={true}
                                    hideCalendarIcon={true}
                                    visibleDateRange={this.state.relativeMaterialDateRangeAbsolute}
                                    noSelectionPlaceholder={['activities', 'sales'].indexOf(selectedTab) === -1 ? this.tr("No selection") : this.tr("All time")}
                                    relativeTimespanExplainer={this.tr("Choose from predefined relative timespans. If you save a report with a relative timespan, \
                                        the duration will remain the same and the date range will update accordingly each time you open it.")}
                                />
                            </div>
                        </div>}
                        {noCurrencyReports.indexOf(selectedTab) < 0 && currencies.length > 1 && <div className='reportsToolbarItem'>
                            <div className='reportsToolbarItemName'>
                                <AttachMoney className='reportsToolbarItemIcon' />
                                {this.tr('Selected currency')}:
                            </div>
                            <div className='reportsToolbarItemEditor'>
                                <DataList
                                    isDisabled={isLoading}
                                    variant="standard"
                                    className="selectField"
                                    classNamePrefix="gura"
                                    value={currencies.find(c => c.id == selectedCurrency)}
                                    options={currencies}
                                    onChange={(c) => this.switchCurrency(c.id)}
                                />
                            </div>
                        </div>}
                        </div>
                        <div className='right'>
                            {!isLoading && activeFilterAmount > 0 && <div className='activeFilterIndicator'>
                                <Tooltip title={activeFilterContent} arrow classes={{ tooltip: 'darkblue-tooltip' }}>
                                    <div>
                                        <FilterAlt />
                                        <p>{activeFilterAmount == 1 ? this.tr('${filterAmount} active filter', {filterAmount: activeFilterAmount}) : this.tr('${filterAmount} active filters', {filterAmount: activeFilterAmount})}</p>
                                    </div>
                                </Tooltip>
                                <button onClick={() => this.grid.current && this.grid.current.setFilterModel(null)}>{this.tr("Clear all")}</button>
                                
                            </div>}
                            <div className={cardStyles.actionButtons}>
                                <ContextMenu
                                    //@ts-ignore
                                    className={'toolbar-menu'}
                                    disabled={isLoading}
                                    buttonProps={{
                                        color: 'green',
                                        stickyIcon: true,
                                        size: 'large',
                                    }}
                                    variant="outlined"
                                    icon={<UnfoldMore />}
                                    size="medium"
                                    placement={'bottom-end'}
                                    >
                                    <MenuItem key="expandGroups" onClick={() => this.grid.current?.gridApi?.expandAll()}>
                                        <UnfoldMore />
                                        {this.tr('Expand all groups')}
                                    </MenuItem>
                                    <MenuItem key="collapseGroups" onClick={() => this.grid.current?.gridApi?.collapseAll()}>
                                        <UnfoldLess />
                                        {this.tr('Collaps all groups')}
                                    </MenuItem>
                                </ContextMenu>
                                <ContextMenu
                                    //@ts-ignore
                                    className={'toolbar-menu'}
                                    disabled={isLoading}
                                    buttonProps={{
                                        color: 'green',
                                        stickyIcon: true,
                                        size: 'large',
                                    }}
                                    variant="outlined"
                                    icon={<CloudDownload />}
                                    // label={this.tr('Actions')}
                                    size="medium"
                                    placement={'bottom-end'}
                                    >
                                    <MenuItem key="excel" onClick={() => this.grid.current?.exportAsExcel()}>
                                        <Download />
                                        {this.tr('Export as Excel')}
                                    </MenuItem>
                                    <MenuItem key="csv" onClick={() => this.grid.current?.exportAsCSV()}>
                                        <Download />
                                        {this.tr('Export as CSV')}
                                    </MenuItem>
                                </ContextMenu>
                                { this.context.addons.chatgpt &&
                                    <SummarizeWithAIButton onGenerate={(prompt: string) => this.summarizeWithAI(prompt)} loading={summaryLoading} />
                                }
                            </div>
                        </div>
                    </div>
                    <AgGridWrapper
                        selectedTemplateName={template?.name || ""}
                        viewName={selectedTab}
                        ref={this.grid}
                        dataTypeDefinitions={dataTypeDefinitions}
                        columns={columns}
                        rows={rows}
                        isLoading={isLoading || isApplyingFilterState}
                        filterState={filterState}
                        onFilterStateChanged={(fromUI) => {
                            const activeFilterData = this.getActiveFilters() || {};
                            this.setState({ activeFilterData });

                            if (fromUI) {
                                this.setDirty(true);
                            }
                        }}
                        onFilterStateStartApplying={this.onFilterStateStartApplying}
                        onFilterStateApplied={this.onFilterStateApplied}
                    />
                </WithTabs>
                <ReportsGallery
                    open={galleryOpen}
                    onSelectTemplate={this.onSelectTemplate}
                    onClose={this.closeGallery}
                    templates={templates}
                    onUpdateTemplate={this.onUpdateTemplate}
                    editTemplate={(template: TemplateData, deleted: boolean) => {
                        if (deleted) {
                            this.openTemplateDeleteConfirmation(template);
                        }
                        else {
                            this.openTemplateEdit(template);
                        }
                    }}
                />
                {this.saveTemplateDialogShouldBeShown() && <SaveTemplateDialog 
                    {...this.getSaveTemplateDialogProps()}
                />}
                {summary && <div className='dark-bg' />}
                {summary && <div className='summary-container'><button onClick={() => this.setState({summary: undefined})}><Close/></button> <div dangerouslySetInnerHTML={{__html: summary}} /></div> }
            </div>
        );
    }
}

export default withSnackbar(NewReportsView);
